/*
因應不同客戶做全域設定
lang: 語系設定
logo：客戶logo
 */
const DEFAULT = {
  lang: 'en-US',
  logo: 'skywatch-logo.svg',
  menus: ['monitor', 'mission', 'device', 'event', 'dash', 'message', 'history', 'dash'],
  measurement: 'metric',
  domain: '',
  name: 'default',
};

// 牡丹水庫
const muDan = {
  ...DEFAULT,
  lang: 'zh-TW',
  name: 'muDan',
};

// iveda
const iveda = {
  ...DEFAULT,
  logo: 'iveda-logo.webp',
  measurement: 'imperial',
  domain: '@iveda.com',
  name: 'iveda',
};

// 中華電信學院
const chtti = {
  ...DEFAULT,
  logo: 'cht-logo.svg',
  menus: ['monitor', 'mission', 'device', 'event', 'dash', 'message', 'dash'],
  name: 'chtti',
};

const customers = [muDan, iveda, chtti];

export default {
  default: DEFAULT,
  muDan,
  iveda,
  chtti,
  findCustomer: (account) => customers.find((customer) => customer.domain && account.endsWith(customer.domain)),
};
